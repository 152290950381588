.content-container {
  display: flex;
  flex-direction: column;
  /* gap: 25px; */
  width: 100%;
  padding: 2em;
  padding-top: 85px;
  /* margin: 65px 3% 0 3%; */
}

.content-container--body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0;
  border-radius: 5px 5px 0 0;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  position: relative;
}
.tabs-container {
  margin-bottom: 2em;
  width: 100%;
  height: auto;
  background: white;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* margin-top: -25px; */
  /* margin-left: 1em; */
}
.content-area {
  /* padding: 2em 1em; */
  /* padding: 1em; */
}
