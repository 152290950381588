@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,500&display=swap");
body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #5095c50f !important;
}
html {
  font-size: 14px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-content-divider {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.form-grid-auto {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 1em;
}

#section-title {
  margin-bottom: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #1a2027;
  font-size: 1.2rem;
}
.head-button-container {
  display: flex;
  gap: 1em;
  /* justify-content: flex-end; */
  margin-bottom: 1em;
}
.info-container {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.596);
  margin: 1em 0px;
  padding: 8.5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.176);
  border-radius: 4px;
}

.css-16awh2u-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  /* padding: 0 !important; */
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  /*DateSelect i components*/
  width: 100%;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  /* Select label position nÃ¤r inget value finns */
  -webkit-transform: translate(14px, 10px) scale(1) !important;
  transform: translate(14px, 10px) scale(1) !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  /* Datum select padding */
  /* padding: 9px !important; */
}

.css-1bn53lx {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  padding-right: 0px !important;
}
.MuiInputLabel-root {
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}

.main-container {
  display: flex;
  transition: 0.2s ease;
}
.main-content {
  margin-left: auto;
  width: 90%;
  transition: 0.2s ease;
}
.main-content_large {
  width: 95%;
}

.overlay {
  width: 100%;
  height: 100%;
  /* max-height: 100vh; */
  position: absolute;
  position: fixed;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  backdrop-filter: blur(1px) brightness(50%);
  top: 0;
  z-index: 2000;
}
.MuiOutlinedInput-root {
  background: white;
}
P {
  margin: 0px !important;
}
legend {
  /* *Fixar sÃ¥ man inte behÃ¶ver ha label i select */
  width: unset !important;
}

.mobile-datepicker input::placeholder {
  color: white;
  opacity: 1;
}

.wrapper {
  padding: 2em 1.2em;
  padding-bottom: 1em;
  background: white;
  border-radius: 10px !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
}
.wrapper-title {
  font-size: 1.2em;
  /* background-color: #f3f7fa; */
  /* width: fit-content; */
  /* padding: 3px 10px; */
  margin-top: -1em;
  margin-bottom: 1.3em;
  font-weight: 500;
  border-radius: 5px;
  /* color: #555; */
  color: rgb(25, 118, 210);
}
.MuiPaper-root {
  border-radius: 10px !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
}

@media screen and (max-width: 1200px) {
  .main-content {
    width: 100%;
    margin: 0;
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.action {
  position: fixed;
  top: 20px;
  right: 30px;
}

.action .profile {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.action .profile img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.action .menu {
  position: absolute;
  top: 120px;
  right: -10px;
  padding: 10px 20px;
  background: #fff;
  width: 200px;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
}

.action .menu.active {
  top: 80px;
  visibility: visible;
  opacity: 1;
}

.action .menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 28px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
}

.action .menu h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0;
  font-weight: 500;
  color: #555;
  line-height: 1.5em;
}

.action .menu h3 span {
  font-size: 14px;
  color: #cecece;
  font-weight: 300;
}

.action .menu ul li {
  list-style: none;
  padding: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

.action .menu ul li img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: 0.5s;
}

.action .menu ul li:hover img {
  opacity: 1;
}

.action .menu ul li a {
  display: inline-block;
  text-decoration: none;
  color: #555;
  font-weight: 500;
  transition: 0.5s;
}

.action .menu ul li:hover a {
  color: #ff5d94;
}
