.newSupplier-form-left {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: 1em;
}

.newSupplier-form-left--div1 {
  grid-area: 1 / 1 / 2 / 5;
}
.newSupplier-form-left--div2 {
  grid-area: 2 / 1 / 3 / 3;
}
.newSupplier-form-left--div3 {
  grid-area: 2 / 3 / 3 / 5;
}
.newSupplier-form-left--div4 {
  grid-area: 3 / 1 / 4 / 5;
}
.newSupplier-form-left--div5 {
  grid-area: 4 / 1 / 5 / 5;
}
.newSupplier-form-left--div6 {
  grid-area: 5 / 1 / 6 / 5;
}
.newSupplier-form-left--div7 {
  grid-area: 6 / 1 / 6 / 5;
}
.newSupplier-form-left--div8 {
  grid-area: 7 / 1 / 7 / 3;
}
.newSupplier-form-left--div9 {
  grid-area: 7 / 3 / 8 / 5;
}

.newSupplier-form-right {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: 1em;
}

.newSupplier-form-right--div1 {
  grid-area: 1 / 1 / 2 / 5;
}
.newSupplier-form-right--div2 {
  grid-area: 2 / 1 / 3 / 5;
}
.newSupplier-form-right--div3 {
  grid-area: 3 / 1 / 4 / 3;
}
.newSupplier-form-right--div4 {
  grid-area: 3 / 3 / 4 / 5;
}
.newSupplier-form-right--div5 {
  grid-area: 4 / 1 / 5 / 5;
}
.newSupplier-form-right--div6 {
  grid-area: 5 / 1 / 6 / 5;
}
.newSupplier-form-right--div7 {
  grid-area: 6 / 1 / 8 / 5;
}
