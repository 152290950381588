.burger {
  position: fixed;
  left: 0;
  background-color: transparent;
  width: 40px;

  margin: 1.5em;
  z-index: 1200;
  cursor: pointer;
}
.burger-layer {
  position: absolute;
  height: 4px;
  border-radius: 5px;
  transition: 0.5s ease;
}
.layer-top {
  width: 50%;
}
.layer-mid {
  width: 100%;
  top: 9px;
}
.layer-bot {
  width: 75%;
  top: 18px;
}

.active-top {
  width: 11px;
  height: 4px;
  transform-origin: center left;
  transform: translate(4px, 0px) rotate(45deg);
}
.active-mid {
  width: 22px;
  height: 4px;
  transform-origin: center left;
  top: 0;
  left: 0;
  transform: translate(4px, 15.5px) rotate(-45deg);
}
.active-bot {
  transform-origin: center left;
  top: 0;
  left: 0;
  width: 11px;
  height: 4px;
  transform: translate(19.5px, 15.5px) rotate(-135deg);
}
@media screen and (max-width: 414px) {
  .burger {
    display: flex;
  }
}
