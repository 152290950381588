.sidebar {
  z-index: 1010;
  position: fixed;
  min-height: 100vh;
  height: 100%;
  width: 10%;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.3s ease;
}
.sidebar_small {
  width: 5%;
  min-width: 5px;
}
.sidebar_open {
  transform: translateX(0px);
}
.sidebar_hidden {
  transform: translateX(-180px);
}
.sidebar,
a {
  color: unset;
}
.sidebar,
a:active {
  color: unset;
}
.hidden {
  transform: translateX(-220px);
}
#navbar-resize-button {
  position: absolute;
  right: -10px;
  top: 75px;
}
#navbar-resize-button-open {
  transform: rotate(180deg);
  position: absolute;
  right: -10px;
  top: 75px;
}

.sidebar--navigation {
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar--navigation-bottom {
  margin-top: auto;
  padding-bottom: 1em;
  padding-top: 2em;
}

.nav-element {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 10px;
  padding-left: 16px;
  border-radius: 0 8px 8px 0;
  /* color: #2b2f3b; */
  text-decoration: none;
  overflow: hidden;
  margin-right: 10px;
  white-space: nowrap;
}
.nav-link:hover {
  background-color: #ececec;
  /* color: white; */
}
.active-link {
  justify-content: center;
}
.nav-icon {
  display: flex;
  padding: 3px;
  font-size: 20px;
  margin-right: 0.5em;
}

.nav-name-mini {
  display: none;
}

@media screen and (max-width: 1200px) {
  .sidebar {
    width: 180px;
  }
}

/* Settings for mini sidebar*/

/* 

.sidebar-mini {
  width: 90px;
  min-width: 90px;
  position: sticky;

  min-height: 100vh;
  height: 100%;

  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 0.3s ease-in-out;
}


@media screen and (max-width: 414px) {
  .sidebar {
    position: absolute;
  }
  #navbar-resize-button,
  #navbar-resize-button-open {
    display: none;
  }
} */
