.Topbar {
  z-index: 1100;
  background-color: rgb(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 3px -1px;
  width: 100%;
  height: 65px;
  padding: 1em;
  /* display: flex; */
  gap: 10px;
  /* justify-content: space-between;
  align-items: center; */
  position: fixed;
  left: auto;
  right: 0;
}
.Topbar-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.Topbar-logo-container {
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .Topbar-logo-container {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
  }
  .Topbar-content {
    justify-content: flex-end;
  }
}
