.status-container table td {
  padding: 3px !important;
  text-align: center;
}
.status-container table input {
  padding: 0px !important;
  text-align: center;
}
.status-container .MuiOutlinedInput-root {
  background: transparent;
}

.project_overview_vertical_table_container {
  flex: 1;
}
@media screen and (max-width: 1263px) {
  .project_overview_vertical_table_container {
    flex: unset;
  }
}
