.dataTable input,
select {
  border-radius: 4px;
  padding: 5px;
  border: 1px solid lightgray;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: auto;
  display: block;
  min-width: 0;
}

.tablePagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.7em;
  align-items: center;
  gap: 5px;
}
.tablePagination select,
span,
input:focus-visible {
  outline: none;
}

table thead th {
  overflow: auto;
}
table > tbody > tr:hover {
  background-color: #ececec;
}
/* Table head*/
.css-1howxi1-MuiTableCell-root {
  /* font-size: 12px !important;
  padding: 10px 5px !important;
  padding-bottom: 0px; */
  /* background-color: #cfe2ff; */
}
/* VerticleTable head*/
/* .css-1ygcj2i-MuiTableCell-root {
 
  color: rgb(0 0 0 / 60%);
  background-color: #cfe2ff;
  border-bottom: 1px solid rgb(155 155 155) !important;
  border-right: 1px solid rgb(155 155 155);
  width: 150px;
} */

/* Table body*/
/* .css-dsuxgy-MuiTableCell-root {
 
  font-size: 12px !important;
  padding: 10px 5px !important;
} */

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: none !important;
}

input,
select {
  border: 1px solid #ced4da;
  border-radius: 4px;
}
table thead tr th:first-child {
  text-align: center !important;
}

tbody tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.04);
} */
