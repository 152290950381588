.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  padding: 1em;
  position: relative;
  overflow: hidden;
}

.login-container::before {
  content: "";
  z-index: -1;
  background-image: url(../../assets/Images/Projektcontrl_text.svg);
  background-size: 50% 100px;
  background-repeat: repeat-y;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 1em;
  top: 100px;
  transform: rotate(0deg);
  opacity: 0.03;
}

.login-form {
  display: grid;
  text-align: center;
  gap: 2em;
  max-width: 450px;
  width: 100%;
  background: white;
  padding: 3em;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 15px;
  color: #31507d;
}

@media screen and (max-width: 1000px) {
  .login-container::before {
    display: none;
  }
}
